import { get } from "@/utils/https";

const enums = {
  // 职业
  profession: "/api/v1/common/cus/infra/dic/profession",
  // 学历
  education: "/api/v1/common/cus/infra/dic/education",
  // 民族
  national: "/api/v1/common/cus/infra/dic/national",
  // 婚姻状况
  maritalStatus: "/api/v1/common/cus/infra/dic/marital-status",
  // 联系人关系
  contactRelation: "/api/v1/common/cus/infra/dic/contact-relation",
  // 公司性质
  companyProperty: "/api/v1/common/cus/infra/dic/company-property",
  // 在职年限
  serveDuration: "/api/v1/common/cus/infra/dic/serve-duration",
  // 员工规模
  staffSize: "/api/v1/common/cus/infra/dic/staff-size",
  // 工作岗位职称
  workTitle: "/api/v1/common/cus/infra/dic/work-title",
  // 学位
  educationDegree: "/api/v1/common/cus/infra/dic/education-degree",
  // 居住状况
  resideStatus: "/api/v1/common/cus/infra/dic/reside-status",
  // 贷款用途
  loanPurpose: "/api/v1/common/cus/infra/dic/loan-purpose",
  // 行业
  industry: "/api/v1/common/cus/infra/dic/industry",
  // 车辆是否解押
  pledge: "/api/v1/common/cus/infra/dic/release/pledge",
  // 融资行业投向
  invest: "/api/v1/common/cus/infra/dic/financing/invest",
  // 担保人关系
  relation: "/api/v1/common/cus/infra/dic/gurantee/relation",
  // 居住年限
  resideYears: "/api/v1/common/cus/infra/dic/reside/years",
  // 居住年限
  workYears: "/api/v1/common/cus/infra/dic/work/years",
  // 工作性质
  workNature: "/api/v1/common/cus/infra/dic/work-nature"
};

const key = `_app_cache_enmu_`;
const EXPIRE_TIME = 48 * 60 * 60 * 1000; // 48小时
// 是否过期（以第一个缓存枚举为时间锚点）
export const checkedExpireTime = function () {
  const _time_key = `${key}expire_time`;
  const recordTimestamp = localStorage.getItem(_time_key);
  const now = Date.now();
  if (!recordTimestamp) {
    localStorage.setItem(_time_key, now + EXPIRE_TIME);
    return false;
  } else {
    return now >= +recordTimestamp;
  }
};
export const getSomeEnums = async (name) => {
  const _key = `${key}${name}`;
  const obj = localStorage.getItem(_key);
  const isExpire = checkedExpireTime();
  if (!obj || isExpire) {
    const { data } = await get(enums[name]);
    let arr = (data || []).map(({ code, name, description }) => ({
      text: name,
      value: code,
      description: description
    }));
    localStorage.setItem(_key, JSON.stringify(arr));
    return Promise.resolve(arr);
  } else {
    return Promise.resolve(JSON.parse(obj));
  }
};
